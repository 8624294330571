import React from 'react'
import '../journey/journey.css'
const AccelerateJourney = () => {
  const data = [
    {
  head:"1000+",
  para:"Containers Launched"
  },
  {
    head:"30+",
    para:"Cloud Engineers"
    },
    {
      head:"100+",
      para:"Customers Served"
      },
      {
        head:"30+",
        para:"Applications Delivered"
        },
]
  return (
   <section style={{background:"black"}}>
    <div className="journey-container">
     <div className="journey-left">
     <h1>
     Fast-track your transition to the digital realm with us.
      </h1>
     </div>
     <div className="journey-right">
     <p>“We're not just collaborators; we're your partners in crafting design systems, refining strategies, and revolutionizing your creative journey. Elevate your creative game, every day.”</p>
     </div>
    </div>
    <div className="project-container">
      {
       data.map((el,i)=>(
        <div className="project-head" key={i}>
        <h1>{el.head}</h1>
        <p>{el.para}</p>
      </div>
       ))
      }
     
    </div>
   </section>
  )
}

export default AccelerateJourney