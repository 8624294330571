import React from 'react'
import { useNavigate } from 'react-router-dom'

const OurServiceOne = (props) => {
    const navigate = useNavigate()
    return (
        <div className="our-service-container">
            <div className="ourservice-left-content" data-aos="zoom-in">
                <div className="our-service-head">
                    <h1>{props.head}</h1>
                    <p>
                        {props.para}
                    </p>
                </div>
                <div className="start-btn">
                    <button onClick={()=> navigate(props.route) }>Know More</button>
                </div>

            </div>
            <div className="our-service-right-content">
                <img src={props.image} alt="App" data-aos="zoom-in" />
            </div>
        </div>
    )
}

export default OurServiceOne