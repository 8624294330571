import React from 'react'
import '../PageNotFound/pagenot.css'
import { useNavigate } from 'react-router-dom'
const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <div className='page-not-container'>
     <div className="">
     <h1>
      PageNotFound
      </h1>
     <div className="back-home">
     <button type='button' onClick={()=>navigate('/')}>Back To Home</button>
     </div>
     </div>
    </div>
  )
}

export default PageNotFound