import React from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import cloud from '../../../assets/cloudnew2.jpg'
import apptest from '../../../assets/testingnew.jpg'
import appdev from '../../../assets/appdevnew.jpg'
import devops from '../../../assets/devopsnew2.jpg'
import { useNavigate } from 'react-router-dom';

const ServiceSlider = () => {
  const navigate = useNavigate()
  const sliderData=[
    {
      serviceHead:"App Development and Moderization:Your Path to Innovation",
      servicePara:" Unlock the potential of your business with Cloudstok's comprehensive app development and modernization solutions.  In a rapidly evolving digital landscape, staying competitive demands more than just an application—it requires a strategic masterpiece. At Cloudstok, we are your dedicated partners, working tirelessly to bring your vision to life.",
      img:appdev,
      route:"/services/app_development",

    },
    {
      serviceHead:"Application Consulting & Testing: Guiding Excellence in Every Byte",
      img:apptest,
      servicePara:"At Cloudstok, we understand that the heart of success lies in the meticulous crafting of your applications. With our unwavering commitment to excellence, we offer a comprehensive suite of application consulting and testing services that ensure your software doesn't just work—it excels.",
      route:"/services/app_testing",
    },
    {
      serviceHead:"Cloud Managed Services: Pioneering Excellence in Cloud Empowerment",
      img:cloud,
      servicePara:"In the digital age, the cloud isn't just a tool—it's a transformative force that propels businesses forward. At Cloudstok, we're not just another provider; we're your partners in harnessing the true power of the cloud. With unparalleled expertise and a strategic approach, we guide your journey to operational brilliance.",
      route:"/services/cloud_manage",
    },
    {
      serviceHead:"DevOps as a Service: Fueling Your Evolution into Excellence",
      img:devops,
      servicePara:"In the fast-paced realm of technology, embracing DevOps isn't just an option—it's a necessity for staying ahead. At Cloudstok, we're not just a provider; we're your gateway to seamless DevOps transformation. With unrivaled expertise, we guide your journey toward operational brilliance.",
      route:"/services/devops",
    }
  ]
  return (
   <div className="slider-container-service">
     <Swiper
        spaceBetween={30}
        cssMode={true}
        keyboard={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        navigation={false}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,Autoplay, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
     {
      sliderData.map((el,i)=>(
        <SwiperSlide key={i}>  
       <div className="slider-image-container" style={{ backgroundImage: `url(${el.img})` }} >
       <div className='slider-heading'>
          <h1 className='new-heading'>
      {el.serviceHead}
          </h1>
          <p className='new-slider-para'>
         {el.servicePara}
          </p>
          <div className="btn-container-service">
          <button onClick={()=> navigate(el.route)}>Read More</button>
          </div>
          </div>
       </div>
      </SwiperSlide>
      ))
     }
       
      
       
      </Swiper>
    
   </div>

  )
}

export default ServiceSlider