import React, { useState } from 'react'
import '../header/header.css'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import logo from '../../assets/white-logo.png'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const Header = () => {
    const [toggle, setToggle] = useState(false)
    const navigate = useNavigate()
    const [navbar,setNavbar] = useState()
  
    const changeBackground = () => {
      if (window.scrollY >= 5) {
        setNavbar(true);
        // setLogo(true);
      } else {
        setNavbar(false);
        // setLogo(false);
      }
    };
    // 2_ related to the navbar change on scroll
    window.addEventListener("scroll", changeBackground)
  return (
   
    <header className={navbar ? "header active-header" : "header"} 
    >
    <nav className='navbar'>
        <div className="logo" >
      <Link to="/">  <img src={logo} alt="" /></Link>
        </div>
        <div className="menu-icon">
            <AiOutlineMenu className='icon-color-menu' onClick={() => setToggle(true)} />
        </div>
        <ul className={`nav-links ${toggle ? "active-nav-links" : ""}`}>
            <div className="close-btn">
                <AiOutlineClose className='icon-color-menu' onClick={() => setToggle(false)} />
            </div>
           
            <li> <NavLink to="/who_we_are">Who We Are</NavLink> </li>
   
            <li>
            <div class="dropdown">
  <Link class="dropbtn">Services</Link>
  <div class="dropdown-content">
  <Link to="/services/app_development">Application Development and Moderization</Link>
  <Link to="/services/app_testing">Application Testing and Consulting</Link>
  <Link to="/services/devops">DevOps as a Service</Link>
  <Link to="/services/cloud_manage">Cloud Managed Services</Link>
  </div>
</div>
 </li>
                <li> <NavLink to="/case_study">Case Study</NavLink> </li>
            <li> <NavLink to="/blog">Blog</NavLink> </li>
          <li>
          <div className="btn-container-1">
            <button type='button' onClick={()=>navigate('/contact') }>Contact Us</button>
        </div>
          </li>
        </ul>
       
    </nav>
</header>
  
  )
}

export default Header