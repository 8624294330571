import React from 'react'
import '../certificate/certificate.css'
import ctImg from '../../../assets/certificate.jpg'
const Certificate = () => {
  return (
    <section className='bg-color'>
        <div className="certificate-container">
          <div className="certificate-container-left">
            <h1>Unveiling Cloudstok: Our Story and Vision</h1>
            <p>
            At CloudStok, we transcend the ordinary. As a burgeoning startup, our core mission is to deliver premium solutions that redefine excellence. With a team of certified experts, we don't just provide services – we craft transformative experiences. Our expertise spans the spectrum, from navigating the cloud landscape to precision application development and DevOps proficiency. We're not merely a service provider; we're your strategic partner, propelling you toward uncharted horizons of success.

<p className='new-para'>In an era where technology drives progress, CloudStok stands at the forefront. Our commitment goes beyond routine; it's about elevating your journey with innovative solutions that matter. Every challenge is an opportunity, and every solution is a testament to our dedication. We're more than a company; we're an embodiment of innovation, a hub of expertise, and a beacon of partnership.</p>

            </p>
          </div>
          <div className="certificate-container-right">
          <img src={ctImg} alt="" />
          </div>
        </div>
    </section>
  )
}

export default Certificate