import React from 'react'
import Layout from '../../layout/Layout'
import Hero from '../../components/hero/Hero'
import PageHeader from '../../components/pageHeader/PageHeader'
import AwsHeading from '../../components/awshead/AwsHeading'
// import ContactForm from '../../components/contactform/ContactForm'
import appdev from '../../assets/appdevnew.jpg'
import appb from '../../assets/banner.jpg'
// import one from '../../assets/ap.jpg'
// import two from '../../assets/successservice.jpg'
// import three from '../../assets/quality.jpg'
// import four from'../../assets/mgt.jpg'
// import five from '../../assets/innovation.jpg'
// import our from '../../assets/serviceOne.png'
import agile1 from '../../assets/agile.jpg'
import agile2 from '../../assets/scrum.jpg'
import agile3 from '../../assets/develope.jpg'
// import CardWrapper from '../../components/card/CardWrapper'
const ApplicationDevelopment = () => {
  // const appData = [
  //   {
  //     appHead:"Our Approach:",
  //     appPara:"With a pulse on the latest industry trends and a team of innovative minds, we take app development beyond the ordinary. Seamlessly weaving creativity and functionality, we create applications that captivate, engage, and deliver results. But we don't stop there—our modernization services breathe new life into legacy systems, ensuring they align with contemporary demands and opportunities.",
  //     appImg:one
  //   },
  //   {
  //     appHead:"Crafted for Success:",
  //     appPara:"From ideation to execution, we guide you through every stage of the app development journey. Our experienced professionals infuse your ideas with technical prowess, resulting in applications that resonate with your audience and elevate your brand.",
  //     appImg:two
  //   },
  //   {
  //     appHead:"Uncompromising Quality:",
  //     appPara:"At Cloudstok, quality is our hallmark. Our rigorous testing and monitoring processes ensure your applications operate flawlessly across diverse environments. With us, you can trust that your app is not only functional but also poised to deliver exceptional user experiences",
  //     appImg:three
  //   },
  //   {
  //     appHead:"Seamless Management:",
  //     appPara:"Beyond development, our commitment extends to app management and monitoring. We take the complexity out of the equation, offering a hassle-free experience that lets you focus on what matters most—your business. No escalations, no unnecessary complications—just smooth operations and optimal performance.",
  //     appImg:four
  //   },
  //   {
  //     appHead:"Your Aspiration, Our Innovation:",
  //     appPara:"Welcome to Cloudstok, where your app development dreams are turned into reality. Whether you're venturing into uncharted territory or revitalizing existing assets, our solutions are designed to propel your business forward. With Cloudstok as your partner, every challenge becomes an opportunity, and every opportunity is seized.",
  //     appImg:five
  //   },
  // ]
  return (
    <Layout>
     <div className="who-we-main-container">
   <Hero img={appdev}/>
    <PageHeader head="SERVICE" heropara="App Development and Moderization:Your Path to Innovation" />
    <section >

    <AwsHeading paragraph="A team of expert full stack
developers and cloud developers to
help you deliver the optimum
application development
experience."
paragraphtwo="The market is fulfilled with a plethora of options. Our Consultant will help you to create, find and manage your application end-to-end. We offer Services of Application Development. Application Monitoring and Management Support for the Application. No escalations and hassle as Cloudstok’s Team will help you through the process to achieve your business goals."
image={appb}
/>

    <div className="agile-container">
      <h1 className="agile-h1">Our expertise in development makes sure that you get the product are you looking for by following the right type of development methodology</h1>
      <div className="agile-img-container">
        <img src={agile1} alt="" />
        <img src={agile2} alt="" />
        <img src={agile3} alt="" />
      </div>
    </div>
    </section>
   </div>
    </Layout>
  )
}

export default ApplicationDevelopment