import React from 'react'
import '../latestblog/latestblog.css'

import {BiLogoFacebook} from 'react-icons/bi'
import {BiLogoTwitter} from 'react-icons/bi'
import {ImPinterest} from 'react-icons/im'
import {BiLogoLinkedin} from 'react-icons/bi'
import {BiLogoXing} from 'react-icons/bi'
import {SiMinutemailer} from 'react-icons/si'
import Layout from '../../layout/Layout'
import azure from '../../assets/azure.jpg'
import PageHeader from '../../components/pageHeader/PageHeader'
import Hero from '../../components/hero/Hero'

import BlogCard from '../blog/BlogCard'

const LatestBlog = () => {
  return (
    <Layout>
      <div className="who-we-main-container">
   <Hero img={azure}/>
    <PageHeader head="ABOUT"
     heropara="Why Azure Devops"
    //  herosubpara="Unlock the potential of your business with Cloudstok's comprehensive app development and modernization solutions. In a rapidly evolving digital landscape, staying competitive demands more than just an application—it requires a strategic masterpiece. At Cloudstok, we are your dedicated partners, working tirelessly to bring your vision to life."
     />
    <section>
    <div className='devops-section'>
        <div className="devops-container">
            <h1 className="devops-h1">What is CI/CD Pipeline?</h1>
            <p className="devops-para">CI/CD stands for Continuous Integration and Continuous Deployment. By continuous integration, we <br />
mean that developers push the code to a common source code repository for eg: GIT, GIT HUB, BIT <br />
Bucket etc. and as soon as the code is pushed the code testing or build server checks the code which can <br />
be done on various platforms like Jenkins, AWS Code Build, etc. Once the code goes through testing the <br />
developer will get feedback about his code whether it worked or not. It helps developers to find and <br />
fix bugs at the initial stage and deliver code at a faster rate.</p>

            <h1 className="devops-h1">What is Azure DevOps?</h1>
            <p className="devops-para">Azure DevOps is a platform developed by Microsoft which works for almost any language targeting any <br />
platform. It supports all major version control systems of today like Git, GitHub, or Bit Bucket and you <br />
can deploy fully functional cross-platform CICD pipelines with total visibility of the development process <br />
and cycles.</p>

            <h1 className="devops-h1">Why opt for Azure DevOps?</h1>
            <p className="devops-para">Competitive Pricing <br />
Continuous Integration and Deployment <br />
Cross Platform Support <br />
Simple to use <br />
Monitoring and visibility</p>

            <h1 className="devops-h1">Conclusion:</h1>
            <p className="devops-para">There are various solutions in the market which provide complete CICD support or you can always look for <br />
alternatives to fulfill your requirement. Azure DevOps is a one-stop solution for organizations looking for <br />
managing their complete CICD from one platform which is also an enterprise-grade solution.</p>

            <div className="devops-button-container">
                <div className="btn-1">
                    <button>style</button>
                </div>
                <div className="btn-1">
                    <button>tech</button>
                </div>
            </div>
        </div>
    </div>    

        <div className="posts-section">
            <p className="posts-para">RELATED POSTS</p>
        <div className="posts-box">
        <BlogCard/>
        </div>
        </div>


        <div className="email-section">
            <div className="email-container">
                <div className="icon">
                    <span><BiLogoFacebook/></span>
                    <span><BiLogoTwitter/></span>
                    <span><ImPinterest/></span>
                    <span><BiLogoLinkedin/></span>
                    <span><BiLogoXing/></span>
                    <span><SiMinutemailer/></span>
                </div>
                <div className='form-line'></div>

                <form action="" className='post-form'>
                    <div className="textarea-container">
                        <label htmlFor="text">ADD COMMENT</label> <br />
                        <textarea name="" id="" cols="30" rows="4"></textarea> <br />
                    </div>

                    <label htmlFor="text">Name *</label> <br />
                    <input type="text" id='name' name='name' /> <br />

                    <label htmlFor="email">Email *</label> <br />
                    <input type="email" id='email' name='email' /> <br />

                    <label htmlFor="text">Website</label> <br />
                    <input type="text" id='name' name='name' /> <br />

                    <div className="chackbox-container">
                        <input type="checkbox" id="comment" name="comment"/>
                        <label for="comment">Save my name, email, and website in this browser for the next time I comment.</label>
                    </div>
                </form>

                <div className="post-button">
                    <button>post comment</button>
                </div>
            </div>
        </div>
        </section>
        </div>
    </Layout>
  )
}

export default LatestBlog