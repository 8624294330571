import React from 'react'
import '../milestone/milstone.css'
import Slider from "react-slick";

import clientImg from '../../../constant/clientImg'

const MileStone = () => {
  const mileData = [
    {
      mileHead:"Sept.2020: Vision Unveiled",
      milePara:"Embarked on innovative journey in cloud technology."
  },
  {
    mileHead:"10 Customers Achieved",
    milePara:"Trusted by 10 valued partners on our visionary expedition."
},
{
  mileHead:"100+ VMs Launched",
  milePara:"Elevated operations with 100+ virtual machines launched."
},
{
  mileHead:"Fintech Success: 5 Clients",
  milePara:"Empowering 5 fintech pioneers with tailored solutions."
},
{
  mileHead:"500+ VMs Orchestrated",
  milePara:"Orchestrated 500+ VMs, optimizing digital landscapes."
},
{
  mileHead:"5 Apps Delivered: Innovation Soared",
  milePara:"Delivered 5 transformative apps, fueling industry innovation.."
},
{
  mileHead:"Team Growth: 20+ Members",
  milePara:"Thrived as a team, expanded by 20+ skilled individuals."
},
{
  mileHead:"DevOps Trailblazing: 5 Startups",
  milePara:"Pioneered DevOps in 5 startups, driving operational excellence."
},
{
  mileHead:"Fintech Advancement: Multifold Impact",
  milePara:"Nurtured multiple fintech startups, fostering growth and innovation."
},
{
  mileHead:"CRM Excellence Attained: Enterprise Scale",
  milePara:"Attained CRM excellence, amplifying enterprise-scale operations."
},
{
  mileHead:"AWS Advanced Partner Attained",
  milePara:"Elevated as an advanced AWS consulting partner, expanding cloud capabilities."
},
{
  mileHead:"Continuing Momentum: Future Awaits",
  milePara:"Continuing the journey, each milestone propels CloudStok forward with purpose."
},

]
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: false,
    centerPadding: "0px",
    slidesToShow: 9,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1800,
    autoplaySpeed: 1800,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
   <div className='main-mile-container'>
    <div className="milestone-container">
         <h2>Charting Our Remarkable Path</h2>
        <section>
       <div className="portfolio-container">
      {
        mileData.map((el,i)=>(
          <div className="portfolio-box" >
          <h4>{el.mileHead}</h4>
        <div className="portfolio-layer">
            <p>{el.milePara}</p>
            
        </div>
    </div>
        ))
      }
       </div>
       
        </section>
    </div>
    <div className="client-container">
      <h1>Our Clients</h1>
     <Slider {...settings}>
          {
            clientImg.map((el, i) => (
                <div className="client-img-container" key={i}>
                  <img className="" src={el.img} alt="" />
                </div>
             
             ))
          }
        </Slider>
    </div>
   </div>
  
  )
}

export default MileStone