import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import case1 from '../../assets/case.jpg'
import case2 from '../../assets/case1.png'
const BlogCard = () => {
    const navigate = useNavigate()
    const caseStudyData=[
        {
            img:case1,
            head:"Why Azure Devops",
            para:"Just the other day I happened to wake up early.That is… ",
            route:"/blog/WhyAzureDevops"
        },
        {
            img:case2,
            head:"AWS Data Transfer Cost Optimization",
            para:"Just the other day I happened to wake up early.That is…",
            route:"/blog/WhyAzureDevops"
        },
       
        {
            img:case2,
            head:"What is Google Kubernetes Engine?",
            para:"Just the other day I happened to wake up early.That is…",
            route:"/blog/WhyAzureDevops"
        },
        {
            img:case2,
            head:"Hybrid Approach using Azure DevOps and Code Pipeline",
            para:"Just the other day I happened to wake up early.That is…",
            route:"/blog/WhyAzureDevops"
        },
      
    ]
  return (
    <div className="case-study-card">
           {
               caseStudyData.map((el,i)=>(
                <div class="blog-new-card-body" onClick={()=>navigate(el.route)}>
                <img src={el.img} alt="" />
                <div class="card-content-case-blog">
                  <Link to={el.route}>
                    <h2>{el.head}</h2>
                   
                  </Link>
                </div>
              </div>
  
               ))
           }
         
       </div>
  )
}

export default BlogCard