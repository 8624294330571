import React from 'react'
import Layout from '../../layout/Layout'
import Hero from '../../components/hero/Hero'
import cloudmg from '../../assets/cloudnew2.jpg'
import PageHeader from '../../components/pageHeader/PageHeader'
import AwsHeading from '../../components/awshead/AwsHeading'
import appb from '../../assets/banner.jpg'
// import appdev from '../../assets/cloud-service-1.jpg'
// import appdev1 from '../../assets/cloud-serive-3.jpg'
// import appdev2 from '../../assets/cloud-service-4.jpg'
// import appdev3 from '../../assets/cloud-service-6.jpg'
// import appdev4 from '../../assets/cloud-service-7.jpg'
// import CardWrapper from '../../components/card/CardWrapper'
const CloudManageService = () => {
  const genData = [
    {
      genHead:"Cloudstok Assessment Stratgey",
      genPara:"The first step while planning a migration is Assessment. CloudStok uses various tools like AWS Discovery and Migration Evaluator (Formerly TSO Logic) to create a business case and to identify the most cost-effective deployment and purchasing options. Our Assessment begins with on-premises inventory discovery and understanding of application dependencies. We provide an optimized business plan to save your cost, optimize performance and give you a seamless experience while migrating your workload to the cloud."
    },
    {
      genHead:"Application Migration and Modernization",
      genPara:"Cloud Migration goes way beyond provisioning infrastructure on cloud platforms and we believe one must have application expertise to understand complex migration. At CloudStok we have the in-house capability of developing enterprise-grade applications which enables us to understand and execute complex migrations."
    },
    {
      genHead:"DevOps and Automation",
      genPara:"At CloudStok we like to automate everything be it Infrastructure Provisioning or Application Deployment. We support and manage almost all major DevOps tools like Jenkins, Docker, Ansible, GitHub, Bitbucket, AWS Code Pipeline, Azure DevOps, Google Kubernetes Engine, Google Cloud Build, and Google Cloud Source Repositories."
    },
    {
      genHead:"Cloudstok Next-Gen Managed Services",
      genPara:"Our Managed Support team is always there to help you round the clock. At CloudStok we believe in a proactive rather than a reactive approach. We use various services like AWS CloudWatch, Azure Monitor, Data Dog APM, New Relic APM, etc. to monitor Infrastructure and Applications and make sure we have the right kind of logs and insights in case of any event."
    }

  ]

 
  return (
  <Layout>
     <div className="who-we-main-container">
   <Hero img={cloudmg}/>
    <PageHeader head="SERVICE"
     heropara="Cloud Managed Services: Pioneering Excellence in Cloud Empowerment"
    //  herosubpara="In the digital age, the cloud isn't just a tool—it's a transformative force that propels businesses forward. At Cloudstok, we're not just another provider; we're your partners in harnessing the true power of the cloud. With unparalleled expertise and a strategic approach, we guide your journey to operational brilliance."
      />
    <section >

    <AwsHeading
image={appb}
paragraphtwo="Being Cloud agnostic, we have Expertise in Deploying and managing Infrastructure on all major Public Cloud Platforms. Organizations often require reliable IT Infrastructure with continuous administration, monitoring, and prompt troubleshooting. CloudStok will help you to plan, implement and manage changes to your IT Infrastructure. With a demonstrated expertise in deploying & managing infrastructure on all major public cloud platforms. Leverage CloudStok’s Assessment, Consulting and Migration services to help you migrate your workload to the cloud."
/>


<div className="gen-container">
  {
    genData.map((el,i)=>(
    <div className="gen-box-container" key={i}>
      <h2 className="gen-h2">{el.genHead}</h2>
      <p className="gen-para">{el.genPara}</p>
    </div>
    ))
  }
    
  </div>
  
    </section>
   </div>
  </Layout>
  )
}

export default CloudManageService