import React from 'react'
import case2 from '../../assets/case1.png'
import Layout from '../../layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import casebg from '../../assets/casenew.jpg'
import case1 from '../../assets/case.jpg'
import Hero from '../../components/hero/Hero'
import PageHeader from '../../components/pageHeader/PageHeader'
const CaseStudy = () => {
    const navigate = useNavigate()
    const caseStudyData=[
        {
            img:case1,
            head:"Fintech Product and Solutions India Pvt. Ltd.",
            para:"Just the other day I happened to wake up early.That is… ",
            route:"/case_study/fintech"
        },
        {
            img:case2,
            head:"Smile Foundation",
            para:"Just the other day I happened to wake up early.That is…",
            route:"/case_study/smilefoundation"
           
        },
        {
          img:case2,
          head:"RDS Competency",
          para:"Just the other day I happened to wake up early.That is…",
          route:"/case_study/rdsOptimization"
         
      },
    ]
  return (
    <Layout>
    <div className="who-we-main-container">
  <Hero img={casebg} /> 
  <PageHeader  heropara="Case Study"/>
       <section>
       <div className="case-study-card-1">
           {
               caseStudyData.map((el,i)=>(
                <div class="card-case" key={i} onClick={()=>navigate(el.route)}>
                <img src={el.img} alt="" />
                <div class="card-content-case">
                  <Link to={el.route}>
                    <h2>{el.head}</h2>
                  </Link>
                </div>
              </div>
               ))
           }
       </div>

       </section>
  </div>
   </Layout>
  )
}

export default CaseStudy