import React from 'react'
import Layout from '../../layout/Layout'
import '../caseStudy/casestudy.css'
import '../../layout/home/banner/banner.css'
import case1 from '../../assets/case1.png'
import case2 from '../../assets/case2.png'
import case3 from '../../assets/case3.png'
import case4 from '../../assets/case4.png'

import case6 from '../../assets/case6.png'
import case7 from '../../assets/case7.png'
import {FaMapLocationDot} from 'react-icons/fa6'
import {MdOutlinePhoneAndroid} from 'react-icons/md'
import {HiOutlineMail} from 'react-icons/hi'
import {CgWebsite} from 'react-icons/cg'
const Troops = () => {

  return (
    <Layout>
        <section>
        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>About The Customer</h1>
            <p>Smile Foundation is to empower underprivileged children, youth and women through relevant education, innovative healthcare and market-focused livelihood programmes. Smile Foundation is to deploy best possible methodology and technology for achieving ideal SROI (social return on investment), to practice and promote good governance. To link business competitiveness of the corporate with social development initiatives; also to sensitize privileged children, youth and citizens in general to promote Civic Driven Change.  </p>
          </div>
          <div className="case-study-right">
            <img src={case1} alt="" />
          </div>
        </div>

        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>Customer Requirement Overview</h1>
            <p>
            Customer had developed an online portal which is accessible via Web or Mobile . They wanted to host it on AWS however being a start up recurring monthly cost was a challenge for them. They wanted to reduce their monthly recurring expense and make sure application is performing as expected.
            </p>
          </div>
          <div className="case-study-right">
            <img src={case3} alt="" />
          </div>
        </div>

        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>Solution provided by CloudStok</h1>
            <p>
            AWS has an offering the Reserved Instance which offers cost savings up-to 70%. CloudStok designed a solution using Reserved Instances to reduce cost  and using S3 to store the database records through automation.
            </p>
          </div>
          <div className="case-study-right">
            <img src={case4} alt="" />
          </div>
        </div>

        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>Tech Stack and Services used</h1>
            <ul>
                <li>AWS Reserved Instances</li>
                <li>AWS KMS</li>
                <li> AWS S3 </li>
                <li>AWS CloudTrail</li>
                <li>AWS CloudWatch</li>
                <li>AWS SNS</li>
              </ul>
          </div>
          <div className="case-study-right">
            <img src={case2} alt="" />
          </div>
        </div>

        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>Business Outcome</h1>
            <p>
            Customer was able to save cost on AWS Monthly billing. CloudStok successfully implemented cluster of reserved instances and Solution for logs and Database storage. Customer is now able to focus more on their core business and spend more on developing their application.
            </p>
          </div>
          <div className="case-study-right">
            <img src={case6} alt="" />
          </div>
        </div>
      
       


        <div className='casestudy-container'>
          <div className="casestudy-contact-left">
            <h1>Contact Us</h1>
            <img src={case7} alt="" />
          </div>
          <div className="about-cloud-email">
              <div className="about-cloud-icons">
                <FaMapLocationDot className='icon-contact'/>
                <MdOutlinePhoneAndroid className='icon-contact'/>
                <HiOutlineMail className='icon-contact'/>
                <CgWebsite className='icon-contact'/>
              </div>
              <div className="cloud-address-container">
              <div className="cloud-address-box">
                <h3>Address</h3>
                <p>C-76 102, 2<sup>th</sup> Floor Noida Sec-63, UP-201301</p>
              </div>
              <div className="cloud-address-box">
                <h3>Phone</h3>
                <p>+91-8800-277-114</p>
              </div>
              <div className="cloud-address-box">
                <h3>E-mail</h3>
                <p>sales@cloudstok.com</p>
              </div>
              <div className="cloud-address-box">
                <h3>Website</h3>
                <p>www.cloudstok.com</p>
              </div>
              </div>
        </div>
          </div>
        </section>
    </Layout>
  )
}

export default Troops