import React from 'react'
import '../banner/banner.css'
import aws2 from '../../../assets/banner.jpg'

const Banner = () => {
  return (
    <section>
        <div className="banner-container">
        <div className="banner-left-content " >
        <h1 className=''>Cloudstok: Your Go-To Cloud Partner</h1>
        <div className="banner-head">
        <p>
        In a marketplace teeming with choices, we stand out as your guiding beacon. Welcome to a realm of possibilities where our consultants craft your vision, shepherd your application journey, and navigate the cloud landscape alongside you. At CloudStok, we are your go-to cloud partner, dedicated to providing comprehensive solutions that cater to every stage of your application's lifecycle
        </p>
        <p>
At CloudStok, we're not just service providers; we're companions on your journey to harnessing the cloud's potential. Experience innovation, reliability, and a collaborative spirit that sets us apart. Choose more than a service; choose a partnership that propels your aspirations forward. Welcome to a world where 'Your Go-To Cloud Partner' is more than a tagline—it's our commitment to your success.
        </p>
        </div>
        
        </div>
        <div className="banner-right-content">
            <img src={aws2} alt="" />
        </div>
        </div>
    </section>
  )
}

export default Banner