import React from 'react'
import Layout from '../../layout/Layout'

import Hero from '../../components/hero/Hero'
import PageHeader from '../../components/pageHeader/PageHeader'
import AwsHeading from '../../components/awshead/AwsHeading'
import '../services/service.css'
import appb from '../../assets/banner.jpg'
import apptestbg from '../../assets/testingnew.jpg'
import speed from '../../assets/speed.png'

import scale from '../../assets/scal.png'
import load1 from '../../assets/load.png'
import load2 from '../../assets/test.png'
import load3 from '../../assets/stress.png'
import load4 from '../../assets/endtest.png'
import load5 from '../../assets/test.png'
import load6 from '../../assets/spiketest.png'
import stab from '../../assets/scaltest.png'
const ApplicationTesting = () => {
  const  speedData =[
    {
      speedImg:speed,
      speedHead:"Speed",
      speedPara:"It determines whether the application responds quickly."
    },
    {
      speedImg:scale,
      speedHead:"Scalability",
      speedPara:"It determines maximum user load the software application can handle."
    },
    {
      speedImg:stab,
      speedHead:"Stability",
      speedPara:"It determines if the application is stable under varying loads."
    }
  ]

  const testingData = [
    {
      testHead:"Load testing",
      testPara:"It checks the application’s ability to perform under anticipated user loads. The objective is to identify performance bottlenecks before the software application goes live.",
      testImg:load1
    },
    {
      testHead:"Stress Testing",
      testPara:"This involves testing an application under extreme workloads to see how it handles high traffic or data processing. The objective is to identify the breaking point of an application.",
      testImg:load2
    },
    {
      testHead:"Endurance Testing",
      testPara:"Under Volume Testing large no. of. Data is populated in a database and the overall software system’s behavior is monitored. The objective is to check the software application’s performance under varying database volumes.",
      testImg:load3
    },
    {
      testHead:"Endurance Testing",
      testPara:"It is done to make sure the software can handle the expected load over a long period of time.",
      testImg:load4
    },
    {
      testHead:"Spike Testing",
      testPara:"This tests the software’s reaction to sudden large spikes in the load generated by users.",
      testImg:load5
    },
    {
      testHead:"Scalability Testing",
      testPara:"The objective of scalability testing is to determine the software application’s effectiveness in scaling up to support an increase in user load. It helps plan capacity addition to your software system.",
      testImg:load6
    }
  ]
 
  const newj = `Application Consulting & Testing: Guiding Excellence in Every Byte`
  return (
    <Layout>
      <div className="who-we-main-container">
        <Hero img={apptestbg} />
        <PageHeader head="SERVICE" 
        heropara={newj}
        // herosubpara="At Cloudstok, we understand that the heart of success lies in the meticulous crafting of your applications. With our unwavering commitment to excellence, we offer a comprehensive suite of application consulting and testing services that ensure your software doesn't just work—it excels."
         />
        <section >
        <AwsHeading paragraphtwo="Our consultants will help you create, find and manage your application end-to-end. We at CloudStok believe in enabling our customers and we believe we can help our customers by providing good consultation services. We have a b development team at CloudStok which brings the latest technologies into effective actions as a part of our solutions. Our approach to application and infrastructure makes us an optimal choice for clients looking for an end to end support. We help customers build applications that they are looking for and provide insights by performing load and performance testing on various parameters. We keep in mind three attributes of Performance Testing while performing:"
image={appb}
/>

        <div className="speed-container">
          {
            speedData.map((el,i)=>(
          <div className="speed-box" key={i}>
            <img src={el.speedImg} alt="" />
            <div className="speed-para-box">
              <h3>{el.speedHead}</h3>
              <p>{el.speedPara}</p>
            </div>
          </div>
            ))
          }
        </div>


        <div className="need-container">
          <h3 className="need-h3">Why do we need Performance Testing?</h3>
          <ol className='need-ol'>
            <li>Performance testing informs the stakeholders about the speed, scalability, and stability of their applications.</li>
            <li>It reveals the necessary improvements needed before the product is released on the market.</li>
            <li>Performance Testing also ensures that the software is not running slow while several users are using it simultaneously</li>
          </ol>
        </div>


        <div className="load-container">
          <h1 className='load-h1'>Why do we nee Peformance Testing?</h1>
          <div className="load-flex-container">
            {
              testingData.map((el,i)=>(
                <div className="load-box" key={i}>
                <img src={el.testImg} alt="" />
                <div className="load-para-box">
                  <h3>{el.testHead}</h3>
                  <p>{el.testPara}</p>
                </div>
              </div>
              ))
            }
        
          </div>
        </div>
        </section>
      </div>
    </Layout>
  )
}

export default ApplicationTesting