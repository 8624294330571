import React from 'react'
import '../footer/footer.css'
import CloudStok from '../../assets/headerlogo.png'

import {CgFacebook} from 'react-icons/cg'

import {IoLogoDribbble} from 'react-icons/io'
import {RiLinkedinFill} from 'react-icons/ri'
import aws2 from '../../assets/banner.jpg'
import { Link } from 'react-router-dom'

const Footer = () => {
    
  return (
    <div className="footer-section">
      <div className='cloudstok-footer'>
        <div className="cloud-image">
          <img src={CloudStok} alt="" />
        </div>

        <div className="company-container">
          <h3>Our Company</h3>
          <p>Who are we</p>
          <p>Services</p>
          <p>Case Study</p>
          <p>Blogs</p>
        </div>

        <div className="company-container">
          <h3>What We Do</h3>
          <p>App Development & Modernization</p>
          <p>App Testing & Consulting</p>
          <p>DevOps as a Service</p>
          <p>Cloud Manage Service</p>
        </div>

        <div className="aws-image">
          <img src={aws2} alt="" />
        </div>
    </div>

    <div className="copy-section">
      <div className="copy-footert">
        <div className="copy-para">
          <p>Cookies | Security & Data Protection Practices | Marketing Privacy Notice | Candidate Privacy Notice  | Diversity Inclusion and Opportunity | Candidate Privacy Notice (Summary) | Vulnerability Disclosure Notice</p>
        </div>
        <div className="copy-icon">
          <p>&copy; 2020 CloudStok. All rights reserved</p>
          <div className="icon-box">
            <Link to='#'><CgFacebook/></Link>
            <Link to="#"><IoLogoDribbble/></Link>
            <Link to="https://www.linkedin.com/company/cloudstok-technologies-private-limited/mycompany/" target='_blank'><RiLinkedinFill/></Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Footer