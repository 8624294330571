import React from 'react'
import Layout from '../Layout'
import Banner from './banner/Banner'
import Certificate from './certificate/Certificate'
import OurServices from './ourservices/OurServices'
import AccelerateJourney from './journey/AccelerateJourney'

import MileStone from './milestone/MileStone'
import ServiceSlider from './banner/ServiceSlider'
import ContactForm from '../../components/contactform/ContactForm'

const Home = () => {
 
  return (
    <Layout>
      <ServiceSlider/>
    <Banner/>
    <Certificate/>
    <OurServices/>
    <AccelerateJourney/>
    <MileStone/>
    <ContactForm/>
  
    </Layout>
  )
}

export default Home