import React from 'react'
import Layout from '../../layout/Layout'
import Hero from '../../components/hero/Hero'
import PageHeader from '../../components/pageHeader/PageHeader'
import AwsHeading from '../../components/awshead/AwsHeading'
import appb from '../../assets/banner.jpg'
// import appdev from '../../assets/devop-service-1.jpg'
// import appdev1 from '../../assets/devops-service-2.jpg'
// import appdev2 from '../../assets/devops-service-3.jpg'
// import appdev3 from '../../assets/devops-service-4.jpg'
// import appdev4 from '../../assets/devops-service-5.jpg'
import devops from '../../assets/devopsnew2.jpg'
import tools1 from '../../assets/tools1.png'
import tools2 from '../../assets/tools2.png'
import tools3 from '../../assets/tools3.png'
import tools4 from '../../assets/tools4.png'
import tools5 from '../../assets/tools5.png'
import tools6 from '../../assets/tools6.png'
import tools7 from '../../assets/tools7.png'
import tools8 from '../../assets/tools8.png'
import ansible from '../../assets/images/ansible.jpg'
import zabbix from '../../assets/images/zabbix.jpg'
import terraform from '../../assets/images/terraform.jpg'
import puppet from '../../assets/images/puppet.jpg'
import promenthes from '../../assets/images/promenthes.jpg'
import gafana from '../../assets/images/gafana.jpg'
import git from '../../assets/images/git.jpg'
import gitlab from '../../assets/images/gitlab.jpg'
import kubernets from '../../assets/images/kubernets.jpg'
import jenkins from '../../assets/images/jenkins.jpg'
import graph from '../../assets/graph.jpg'
import datadog from '../../assets/images/datadog.jpg'
import circleci from '../../assets/images/circleci.jpg'
import chef from '../../assets/images/chef.jpg'

// import CardWrapper from '../../components/card/CardWrapper'
const DevopsService = () => {
 
  return (
   <Layout>
<div className="who-we-main-container">
   <Hero img={devops}/>
    <PageHeader head="SERVICE" 
    heropara="DevOps as a Service: Fueling Your Evolution into Excellence"
    // herosubpara="In the fast-paced realm of technology, embracing DevOps isn't just an option—it's a necessity for staying ahead. At Cloudstok, we're not just a provider; we're your gateway to seamless DevOps transformation. With unrivaled expertise, we guide your journey toward operational brilliance."
     />
    <section >
    <AwsHeading
image={appb}
paragraphtwo="The market is fulfilled with a plethora of options. Our Consultant will help you to create, find and manage your application end-to-end. We offer Services of Application Development. Application Monitoring and Management Support for the Application. No escalations and hassle as Cloudstok’s Team will help you through the process to achieve your business goals.——–Cloudstok is entirely focused on providing rapid, tangible results to its clients using highly effective software development methodologies and Amazon Web Services. Many companies claim to implement DevOps tools and processes, but we live the culture of DevOps daily. We demonstrate that culture for every customer and live up to it ourselves.

Everything we say and do is based on our experiences and our successes."
/>

    <div className="tools-container">
      <h3 className="tools-h3">AWS Tools</h3>

      <div className="tools-flex-container">
        <img src={tools1} alt="" />
        <img src={tools2} alt="" />
        <img src={tools3} alt="" />
        <img src={tools4} alt="" />
        <img src={tools5} alt="" />
        <img src={tools6} alt="" />
        <img src={tools7} alt="" />
        <img src={tools8} alt="" />
      </div>
    </div>


    <div className="tools-container">
      <h3 className="tools-h3">DevOps Support Tools</h3>

      <div className="tools-flex-container">
        <img src={ansible} alt="" />
        <img src={zabbix} alt="" />
        <img src={terraform} alt="" />
        <img src={puppet} alt="" />
        <img src={promenthes} alt="" />
        <img src={gafana} alt="" />
        <img src={git} alt="" />
        <img src={gitlab} alt="" />
        <img src={kubernets} alt="" />
        <img src={jenkins} alt="" />
        <img src={graph} alt="" />
        <img src={datadog} alt="" />
        <img src={circleci} alt="" />
        <img src={chef} alt="" />
      </div>
    </div>
   
    </section>
   </div>
   </Layout>
  )
}

export default DevopsService