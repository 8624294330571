import React from 'react'
import '../caseStudy/rds.css'
import { Link } from 'react-router-dom'
import dwn from'../../assets/download.svg'
import {AiOutlineLink} from 'react-icons/ai'
import banner from '../../assets/banner.jpg'
import {BsFacebook,BsLinkedin,BsEnvelope} from 'react-icons/bs'
import Layout from '../../layout/Layout'
const RdsOptimization = () => {
    const awsData = [
        {
            iconData:"",
            paraData:"Advance Consulting Partner(Public Sector Partner)"
        },
        {
            iconData:"",
            paraData:"Advance Consulting Partner"
        },
       
    ]

  return (
<Layout>
<div className="rds-container">
{/* section two  */}
<div className="section-two">
   <section>
   <div className="section-two-content padding">
    <p>Find an AWS Partner / ...</p>
    <h2 className='company-name cld'>Cloudstok</h2>
    <p>Reliable AWS Select Tier Partner with 3-year experience in IT ,Web-App,<br /> Mobile-App, Cloud Solutions, DevOps and other IT services.</p>
    <button className='contact-btn'><Link to="https://cloudstok.com/contact" target='_blank'>Contact Partner</Link></button>
   </div>
   </section>
</div>
{/* section three  */}
<div className="section-three">
   <section>
   <div className="section-three-content padding">
    <h2 className='company-name'>AWS Partner Highlights</h2>
    <div className="section-three-card">
        {
            awsData.map((el,i)=>(
                <div className="section-three-card-body" key={i}>
                <span>{el.iconData}</span>
                <p>{el.paraData}</p>
            </div>
            ))
        }
       
    </div>
   </div>
   </section>
</div>
{/* section four  */}
<div className="section-four">
   <section>
   <div className="section-four-content padding">
   <div className="section-four-head">
   <p>
   In a marketplace teeming with choices, we stand out as your guiding beacon. Welcome to a realm of possibilities where our consultants craft your vision, shepherd your application journey, and navigate the cloud landscape alongside you. At CloudStok, we are your go-to cloud partner, dedicated to providing comprehensive solutions <br /> that cater to every stage of your application's lifecycle
   </p>
    {/* <img src={logo} alt="" /> */}
   </div>
   <div className="section-four-card">
    <div className="section-four-card-body">
        <h2 className='c-heading'>AWS PARTNER WEBSITE</h2>
       <Link style={{color:"#006cff",  textDecoration:'none'}} to="https://cloudstok.com/" target='_blank'>Cloudstok.com</Link>
    </div>
    <div className="section-four-card-body">
        <h2 className='c-heading'>HEADQUARTERS</h2>
        <p  className='c-para' style={{color:"#006cff", fontSize:16}}>
        Cloudstok Technologies Private Limited

102, C-76, SECTOR-63,

NOIDA GAUTAM BUDDHA NAGAR, UTTAR PRADESH
        </p>
    </div>
    <div className="section-four-card-body">
        <h2 className='c-heading'>SHARE FEEDBACK</h2>
        <Link style={{color:"#006cff", textDecoration:'none'}} to="/feedback">Rate the Partner</Link>
    </div>
    <div className="section-four-card-body">
        <h2 className='c-heading'>SHARE VIA...</h2>
        <div className="icon-container">   
        <BsFacebook className='social-icon'/>   
        <Link to="https://www.linkedin.com/company/cloudstok-technologies-private-limited/" target='_blank'>
            <BsLinkedin className='social-icon'/>
        </Link>
       <Link to="#"> <BsEnvelope className='social-icon'/></Link>
        </div>
    </div>
   </div>
   <p className='italic'>AWS Partner descriptions are provided by the AWS Partner and are not verified by AWS.</p>
   </div>
   </section>
</div>
{/* section five  */}
<div className="section-five">
   <section>
   <div className="section-five-content padding">
   <div className="header-main-heading">
    <h1>AWS Validated Qualifications</h1>
   </div>
   <div className="section-five-card">
    <div className="section-five-card-body">
        <h2 className='c-heading'>PARTNER PROGRAMS</h2>
        <p style={{color:"#006cff"}} className=''>AWS Solution Provider Program</p>
    </div>
    <div className="section-five-card-body">
        <h2 className='c-heading'>AWS SERVICE VALIDATIONS</h2>
       <p style={{color:"#006cff"}}>Amazon RDS Delivery</p>
    </div>
    <div className="section-five-card-body">
        <h2 className='c-heading'>AWS CERTIFICATIONS</h2>
        <ul>
          <img src={banner} alt="" />
        </ul>
    </div>
   </div>
   </div>
   </section>
</div>
{/* section six  */}
<div className="section-six">
   <section>
   <div className="section-six-content padding">
   <div className="header-main-heading">
    <h1>Practices (1)</h1>
   </div>
   <div className="section-six-card">
    <div className="section-six-card-body">
     <div className="img-container">
     <img src={dwn} alt="" />
      <p className='ad'>ADVANCED</p>
     </div>
     <h2 className='c-heading'>AMAZON RDS DELIVERY</h2>
     <h3 style={{color:"#006cff"}}>All-Around Amazon RDS Consulting and Implementation</h3>
     <p >Cloudstok builds web and mobile apps backed by cost-efficient, fast, and secure cloud-native databases and DWHs on Amazon RDS. We develop Amazon RDS-based solutions from scratch, migrate existing databases to AWS, and offer maintenance and optimization to ensure 24/7 availability, high performance and security of your database systems.
</p>
    </div>
   </div>
   </div>
   </section>
</div>

{/* section seven  */}
<div className="section-five">
   <section>
   <div className="section-six-content padding">
   <div className="header-main-heading">
    <h1>Cloudstok Customer References (2) </h1>
   </div>
   <div className="section-six-card">
    <div className="section-three-card-body flex-body">
       <Link style={{color:"#006cff"}} to="https://namastecredit.com/" target='_blank'>Open doors Pvt. Ltd.<AiOutlineLink/></Link>
    </div>
    <div className="section-three-card-body flex-body">
       <Link style={{color:"#006cff"}} to="https://aarrsolprivatelimited.com/" target='_blank'> Aarrsol Private Limited  <AiOutlineLink/></Link>
    </div>
   
   </div>
   </div>
   </section>
</div>
{/* section-8  */}
<div className="section-8">
   <section>
   <div className="section-six-content padding">
   <div className="header-main-heading">
    <h1>Location</h1>
   </div>
   <div className="section-8-card">
        <div className="section-8-body">
        <h2  className='c-heading'>Cloudstok Technologies Private Limited</h2>
<p style={{color:"#006cff"}} className='c-para'>102, C-76, SECTOR-63, NOIDA GAUTAM BUDDHA NAGAR, UTTAR PRADESH
</p>
    </div>
  
   </div>
   </div>
   </section>
</div>
</div>
</Layout>
   
  )
}

export default RdsOptimization