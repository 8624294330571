import React from 'react'
import Layout from '../../layout/Layout'
import '../caseStudy/casestudy.css'
import '../../layout/home/banner/banner.css'
import case1 from '../../assets/case1.png'
import case2 from '../../assets/case2.png'
import case3 from '../../assets/case3.png'
import case4 from '../../assets/case4.png'
import case5 from '../../assets/case5.png'

import case7 from '../../assets/case7.png'
import {FaMapLocationDot} from 'react-icons/fa6'
import {MdOutlinePhoneAndroid} from 'react-icons/md'
import {HiOutlineMail} from 'react-icons/hi'
import {CgWebsite} from 'react-icons/cg'
const Fintech = () => {

  return (
    <Layout>
        <section>
        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>About Cloudstok</h1>
            <p>CloudStok is an innovative young start up with rich experience in latest technologies. Witnessing the problematic areas in Cloud Computing, CloudStok is the brainchild of leading industry experts. After observing the numerous ongoing challenges in the market faced by the customers, the founders of CloudStok are planning to take over the market by incorporating the practices for Client Computing. </p>
              <p className='case-study-new-para'>We are dedicated to providing best in class services in the field of Infrastructure and application development to accelerate our customers' digital journey. We act as a strong technical engine for our clients seeking support for their applications or infrastructure. Our strong approach towards application and infrastructure makes us an optimal choice for clients looking for an end to end support.</p>
          </div>
          <div className="case-study-right">
            <img src={case1} alt="" />
          </div>
        </div>
         <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>About The Clients</h1>
            <ul>
                <li>At One money, Customer is building user experience tools with a privacy-first approach for our users to manage their consents.</li>
                <li>They are also building a reliable API for the hundreds of financial service providers across the country to use, for secure consent-driven receipt and transfer of their users' data.</li>
                <li>Customer has a mission to champion data governance. The parent company, FinTech, is building a data governance platform for data fiduciaries, i.e. any organization having your data, in the financial services business to embrace the path of becoming "privacy-positive" enterprises.</li>
              </ul>
          </div>
          <div className="case-study-right">
            <img src={case2} alt="" />
          </div>
        </div>
        
        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>The Business Need </h1>
            <ul>
                <li>As an organization which is a fintech OneMoney needs to follow some compliance to get ISO Certification.</li>
                <li>They had configured containers manually and to achieve the certification they had to automate their infrastructure deployment and provisioning.</li>
                <li>They were looking for a vendor which can deploy  the infrastructure as per ISO guidelines and help them deploying the microservices on new infrastructure.</li>
                <li>Customer was looking for a vendor which have in-house application development and infrastructure capabilities to guide them in deploying microservices as per guidelines.</li>
                <li>The new infrastructure had to be deployed using terraform.</li>
              </ul>
          </div>
          <div className="case-study-right">
            <img src={case3} alt="" />
          </div>
        </div>

        <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>The Solution</h1>
            <ul>
                <li>AWS Fargate was used to create ECS Cluster to host 4 micro services.</li>
                <li>High Availability of the application is be considered while deploying the infrastructure as ECS and RDS are launched across two availability zones. </li>
                <li>Admin UI is served using Cloudfront and S3 as the microservices API are CORS compliant and are able to serve the requests on the provided URL.</li>
                <li>Launching and Configuring Application Load Balancer which will serve as a front end and only public facing entity.</li>
                <li>Global accelerator is used to provide static I.P. for inbound requests.</li>
                <li>NAT Gateway is used to provide Static IP for outbound purpose.</li>
                <li>Deploying complete infrastructure via a terraform template apart from DNS Zones and CICD Pipelines.</li>
                <li>ECS Cluster will deploy latest image pushed to ECR Container registry.</li>
              </ul>
          </div>
          <div className="case-study-right">
            <img src={case4} alt="" />
          </div>
        </div>

 <div className="solution-case">
 <div className="">
 <h1>
  The Solution Architecture
  </h1>
  <img src={case5} alt="" />
 </div>
 </div>

 <div className='casestudy-container'>
          <div className="case-study-left">
            <h1>The Business Benefits</h1>
            <p>DevOps experts at CloudStok provided the whole solution in just two weeks in which the right solution and configuration that could support the seamless functioning of the application was designed to achieve the desired results. </p>
           <div className="benifits-list">
           <p>Benefits that OneMoney witnessed were:</p>
           <ul>
                <li>AWS Fargate was used to create ECS Cluster to host 4 micro services.</li>
                <li>High Availability of the application is be considered while deploying the infrastructure as ECS and RDS are launched across two availability zones. </li>
                <li>Admin UI is served using Cloudfront and S3 as the microservices API are CORS compliant and are able to serve the requests on the provided URL.</li>
                <li>Launching and Configuring Application Load Balancer which will serve as a front end and only public facing entity.</li>
                <li>Global accelerator is used to provide static I.P. for inbound requests.</li>
                <li>NAT Gateway is used to provide Static IP for outbound purpose.</li>
                <li>Deploying complete infrastructure via a terraform template apart from DNS Zones and CICD Pipelines.</li>
                <li>ECS Cluster will deploy latest image pushed to ECR Container registry.</li>
              </ul>
           </div>
          </div>
          <div className="case-study-right">
            <img src={case3} alt="" />
          </div>
        </div>
       


        <div className='casestudy-container'>
          <div className="casestudy-contact-left">
            <h1>Contact Us</h1>
            <img src={case7} alt="" />
          </div>
          <div className="about-cloud-email">
              <div className="about-cloud-icons">
                <FaMapLocationDot className='icon-contact'/>
                <MdOutlinePhoneAndroid className='icon-contact'/>
                <HiOutlineMail className='icon-contact'/>
                <CgWebsite className='icon-contact'/>
              </div>
              <div className="cloud-address-container">
              <div className="cloud-address-box">
                <h3>Address</h3>
                <p>C-76 102, 2<sup>th</sup> Floor Noida Sec-63, UP-201301</p>
              </div>
              <div className="cloud-address-box">
                <h3>Phone</h3>
                <p>+91-8800-277-114</p>
              </div>
              <div className="cloud-address-box">
                <h3>E-mail</h3>
                <p>sales@cloudstok.com</p>
              </div>
              <div className="cloud-address-box">
                <h3>Website</h3>
                <p>www.cloudstok.com</p>
              </div>
              </div>
        </div>
          </div>
        </section>
    </Layout>
  )
}

export default Fintech