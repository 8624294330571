import React from 'react'
import '../ourservices/ourservices.css'
import OurServiceOne from './OurServiceOne'

import serviceone from '../../../assets/case.jpg'

import servicethree from '../../../assets/case1.png'
import servicefour from '../../../assets/rds.jpg'
const OurServices = () => {
  return (
 <section>
  <div className="our-service-wrapper">
    <h1 className='solution'>Success Stories: Unveiling Our Case Studies</h1>
    <OurServiceOne head="Fintech Product and Solutions India Pvt. Ltd." 
    para="Customer has a mission to champion data governance. The parent company, FinTech, is building a data governance platform for data fiduciaries, i.e. any organization having your data, in the financial services business to embrace the path of becoming privacy-positive enterprises."
    image={serviceone}
    route="/case_study/fintech"
    />
    <OurServiceOne  head="Smile Foundation" 
    para="Smile Foundation is to empower underprivileged children, youth and women through relevant education, innovative healthcare and market-focused livelihood programmes. Smile Foundation is to deploy best possible methodology and technology for achieving ideal SROI (social return on investment), to practice and promote good governance. To link business competitiveness of the corporate with social development initiatives; also to sensitize privileged children, youth and citizens in general to promote Civic Driven Change."
    image={servicethree}
    route="/case_study/smilefoundation"
    />
      <OurServiceOne  head="RDS Competency" 
    para="Cloudstok builds web and mobile apps backed by cost-efficient, fast, and secure cloud-native databases and DWHs on Amazon RDS. We develop Amazon RDS-based solutions from scratch, migrate existing databases to AWS, and offer maintenance and optimization to ensure 24/7 availability, high performance and security of your database systems."
    image={servicefour}
    route="/case_study/rdsOptimization"
    />
  </div>
 </section>
  )
}

export default OurServices