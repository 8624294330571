import React from 'react'

const AwsHeading = (props) => {
  return (
    <div className="public-flex-container">
    <div className="public-para">
      <p className="public-para1">{props.paragraph}</p>
      <p className="public-para2">{props.paragraphtwo}</p>
    </div>

    <div className="public-img-container">
      <img src={props.image} alt="" />
    </div>
  </div>
  )
}

export default AwsHeading