import React from 'react'
import Layout from '../../layout/Layout'
import '../blog/blog.css'


import devops from '../../assets/blognew.jpg'
import Hero from '../../components/hero/Hero'
import PageHeader from '../../components/pageHeader/PageHeader';
import BlogCard from './BlogCard';
const Blog = () => {
  
  
  return (
    <Layout>
     <div className="who-we-main-container">
   <Hero img={devops}/> 
   <PageHeader heropara="Blogs"/>
  <section>
<BlogCard/>
  </section>
   </div>
    </Layout>
  )
}

export default Blog