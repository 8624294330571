import React, { useState } from 'react'
import Layout from '../../layout/Layout'
import '../caseStudy/feedback.css'
import logoRight from '../../assets/white-logo.png'
import { Link } from 'react-router-dom'


const ReviewForm = () => {
       const [formData, setFormData] = useState({
              fname: "",
              lname: "",
              company: "",
              companyEmail: "",
              comments: '',

       });

       const handleChange = (e) => {
              const { name, value } = e.target;
              setFormData({ ...formData, [name]: value });
       };

       const handleSubmit = async (e) => {
              e.preventDefault();
              console.log(formData)
              // const res = await fetch(`${process.env.REACT_APP_BASE_URL}/send`, {
              //        method: "POST",
              //        headers: {
              //               "Content-Type": "application/json"
              //        },
              //        body: JSON.stringify(formData)
              // })
              // console.log(res)
              // alert("Your Response Submitted Successfully")
              // setFormData('')
              // Use EmailJS to send the email here
       };
       return (
              <Layout>
                     <div className="rds-container">
                            <section>
                                   <div className="feed-back-container">
                                          <h2>Customer Review</h2>
                                          {/* <hr /> */}
                                   </div>
                                   <div className="form-container">
                                          <div className="form-container-left">
                                                 <h2>Your Information</h2>
                                                 <form className="" onSubmit={handleSubmit} id="myForm">
                                                        <div className="form-input-container">
                                                               <label htmlFor="fname">First Name</label> <br />
                                                               <input type="text"
                                                                      name="fname"
                                                                      placeholder="First Name"
                                                                      value={formData.fname}
                                                                      onChange={handleChange} />
                                                        </div>

                                                        <div className="form-input-container">
                                                               <label htmlFor="lname">Last Name</label> <br />
                                                               <input type="text"
                                                                      name="lname"
                                                                      placeholder="Last Name"
                                                                      value={formData.lname}
                                                                      onChange={handleChange} />
                                                        </div>
                                                        <div className="form-input-container">
                                                               <label htmlFor="name">Company</label> <br />
                                                               <input type="text"
                                                                      name="company"
                                                                      placeholder="Company"
                                                                      value={formData.company}
                                                                      onChange={handleChange} id="" />
                                                        </div>
                                                        <div className="form-input-container">
                                                               <label htmlFor="name">Company Email</label> <br />
                                                               <p className='italic-1'>An email verification will be sent to you by Amazon Web Services, Inc. and its affiliates (AWS) upon form completion.</p>
                                                               <input type="email"
                                                                      name="companyEmail"
                                                                      placeholder="Email"
                                                                      value={formData.companyEmail}
                                                                      onChange={handleChange} />
                                                        </div>

                                                        <div className="form-input-container">
                                                               <label htmlFor="name">Comments</label> <br />
                                                               <p className='italic-1'>Please do not disclose any personal, commercially sensitive, or confidential information.</p>
                                                               <textarea name="comments" id=""
                                                                      placeholder="message"
                                                                      value={formData.comments}
                                                                      onChange={handleChange} cols="30" rows="10"></textarea>
                                                        </div>
                                                        <div className="btn-container">
                                                               <button type='submit'>Send</button>
                                                        </div>
                                                 </form>

                                          </div>
                                          <div className="form-container-right">
                                                 <div className="form-right-content">
                                                        <div className="review-body">
                                                               <h3>Reviewing</h3>
                                                        </div>
                                                        <div className="logo-right-container">
                                                               <img src={logoRight} alt="" />
                                                        </div>
                                                        <div className="address-right">
                                                               <h3>Cloudstok Technologies Private Limited</h3>
                                                               {/* <Link to="https://cloudstok.com/" target='_blank'>https://cloudstok.com</Link> */}
                                                               <p>102, C-76, SECTOR-63,

                                                                      NOIDA GAUTAM BUDDHA NAGAR, UTTAR PRADESH</p>
                                                        </div>
                                                 </div>
                                                 <div className="address-right">
                                                        <p>Questions about leaving a customer review?
                                                               Email us at <Link>support@cloudstok.com.</Link></p>
                                                 </div>
                                          </div>
                                   </div>
                            </section>
                     </div>
              </Layout>
       )
}

export default ReviewForm