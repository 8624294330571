import React from 'react'

const PageHeader = (props) => {
  return (
   <div className="wrapper">
     <div className="journey-felx-container">
          <h1 className='journey-h1'>{props.heropara}</h1>
        </div>
   </div>
  )
}

export default PageHeader