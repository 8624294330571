import client1 from '.././assets/clients/abhisti.png'
import client2 from '.././assets/clients/3m.png'
import client3 from '.././assets/clients/adclear.png'
import client4 from '.././assets/clients/advectus.png'
import client5 from '.././assets/clients/aeon.png'
import client6 from '.././assets/clients/aks.png'
import client7 from '.././assets/clients/ardour.png'
import client8 from '.././assets/clients/beetal.png'
import client9 from '.././assets/clients/ctc.png'
import client10 from '.././assets/clients/damodar.png'
import client11 from '.././assets/clients/datami.png'
import client12 from '.././assets/clients/dc.png'
import client13 from '.././assets/clients/dinotech.png'
import client14 from '.././assets/clients/educa.png'
import client15 from '.././assets/clients/educomp.png'
import client16 from '.././assets/clients/eln.png'
import client17 from '.././assets/clients/fintech.png'
import client18 from '.././assets/clients/funda.png'
import client19 from '.././assets/clients/iari.png'
import client20 from '.././assets/clients/kestone.png'
import client21 from '.././assets/clients/nc.png'
import client22 from '.././assets/clients/onemoney.png'
import client23 from '.././assets/clients/open.png'
import client24 from '.././assets/clients/oxfam.png'
import client25 from '.././assets/clients/proclouder.png'
import client26 from '.././assets/clients/prudas.png'
import client27 from '.././assets/clients/salarynow.png'
import client28 from '.././assets/clients/shellagroup.png'
import client29 from '.././assets/clients/sleepwell.png'
import client30 from '.././assets/clients/smile.png'
import client31 from '.././assets/clients/spiral.png'
import client32 from '.././assets/clients/staquo.png'
import client33 from '.././assets/clients/tipl.png'
import client34 from '.././assets/clients/tipl1.png'
import client35 from '.././assets/clients/troops.png'
import client36 from '.././assets/clients/xr.png'
const clientImg = [
    {
        img:client1
    },
    {
        img:client2
    },
    {
        img:client3
    },
    {
        img:client4
    },
    {
        img:client5
    },
    {
        img:client6
    },
    {
        img:client7
    },
    {
        img:client8
    },
    {
        img:client9
    },
    {
        img:client10
    },
    {
        img:client11
    },
    {
        img:client12
    },
    {
        img:client13
    },
    {
        img:client14
    },
    {
        img:client15
    },
    {
        img:client16
    },
    {
        img:client17
    },
    {
        img:client18
    },
    {
        img:client19
    },
    {
        img:client20
    },
    {
        img:client21
    },
    {
        img:client22
    },
    {
        img:client23
    },
    {
        img:client24
    },
    {
        img:client25
    },
    {
        img:client26
    },
    {
        img:client27
    },
    {
        img:client28
    },
    {
        img:client29
    },
    {
        img:client30
    },
    {
        img:client31
    },
    {
        img:client32
    },
    {
        img:client33
    },
    {
        img:client34
    },
    {
        img:client35
    },
    {
        img:client36
    },
]
export default clientImg