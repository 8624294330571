import React from 'react'

const CardWrapper = ({appData}) => {
  return (
  
    <div className="app-service-container">
    {
        appData.map((el,i)=>(
          <div className="app-service-card" key={i}>
          <div className="app-service-left">
          <h1>
      {el.appHead}
           </h1>
           <p>
            {el.appPara}
           </p>
          </div>
          <div className="app-service-right">
           <img src={el.appImg} alt="" />
          </div>
       
       </div>
        ))
      }
    </div>
        
  )
}

export default CardWrapper