
import React, { useEffect } from "react";
import AOS from "aos";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "aos/dist/aos.css";
import Home from "./layout/home/Home";
import WhoWeAre from "./pages/whoWeAre/WhoWeAre";
import ApplicationDevelopment from "./pages/services/ApplicationDevelopment";
import ApplicationTesting from "./pages/services/ApplicationTesting";
import DevopsService from "./pages/services/DevopsService";
import CloudManageService from "./pages/services/CloudManageService";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Blog from "./pages/blog/Blog";
import CaseStudy from "./pages/caseStudy/CaseStudy";

import Fintech from "./pages/caseStudy/Fintech";
import Troops from "./pages/caseStudy/Troops";
// import ContactForm from "./components/contactform/ContactForm";
import Contact from "./pages/contact/Contact";
import ScrollToTop from "./components/ScrollToTop";
import LatestBlog from "./pages/latestblog/LatestBlog";
import RdsOptimization from "./pages/caseStudy/RdsOptimization";
import ReviewForm from "./pages/caseStudy/ReviewForm";


function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  
  }, []);
  
  return (
  
      <BrowserRouter>
      <ScrollToTop>
      <Routes>
      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/who_we_are" element={<WhoWeAre/>}/>
      <Route exact path="/blog" element={<Blog/>}/>
      <Route exact path="/case_study" element={<CaseStudy/>}/>
      <Route exact path="/services/app_development" element={<ApplicationDevelopment/>}/>
      <Route exact path="/services/app_testing" element={<ApplicationTesting/>}/>
      <Route exact path="/services/devops" element={<DevopsService/>}/>
      <Route exact path="/services/cloud_manage" element={<CloudManageService/>}/>
      <Route exact path="/case_study/fintech" element={<Fintech/>}/>
      <Route exact path="/case_study/smilefoundation" element={<Troops/>}/>
      <Route exact path="/feedback" element={<ReviewForm/>}/>
      <Route exact path="/case_study/rdsOptimization" element={<RdsOptimization/>}/>

      <Route exact path="/blog/WhyAzureDevops" element={<LatestBlog/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      <Route exact path="*" element={<PageNotFound/>}/>
    </Routes>
      </ScrollToTop>
    </BrowserRouter>

  );
}

export default App;
