import React from 'react'
import '../hero/hero.css'
const Hero = (props) => {
  return (
    <div className='hero-container'>
        <div className="hero-container-img" style={{ backgroundImage: `url(${props.img})` }}>
        <h1 className='banner-head-para'>{props.bannerhead}</h1>
        </div>
    </div>
  )
}

export default Hero