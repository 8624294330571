import React from 'react'
import '../contactform/contactform.css'

const ContactForm = () => {
  return (
    <div className="contact-form-section">
        <h1 className='service-head'>Ready to Transform Your Vision into Reality?</h1>
      <section>
      <div className="contact-form-container">
            <form action="">
                <div className="first-name-container">
                    <div className='input-field'>
                        <label htmlFor="text">First Name <span>*</span></label> <br />
                        <input type="text" id='name' name='name' />
                    </div>
                    <div className='input-field'>
                        <label htmlFor="text"> Last Name <span>*</span></label> <br />
                        <input type="text" id='name' name='name' /> <br />
                    </div>
                </div>
<div className="email-container-1">
    
<label htmlFor="email">Business Email *</label> <br />
                <input type="email" id='email' name='email' /> <br />
</div>

                <div className="textarea-container-1">
                    <label htmlFor="text">Message *</label> <br />
                    <textarea name="" id="" cols="30" rows="2" placeholder='Type your message here'></textarea>
                </div>

                <div className="form-button">
                  <button>Submit</button>
                </div>
            </form>
        </div>
      </section>
    </div>
  )
}

export default ContactForm