import React from 'react'
import Layout from '../../layout/Layout'
import Hero from '../../components/hero/Hero'
import heroImg from '../../assets/who-we-are-1.jpg'
import PageHeader from '../../components/pageHeader/PageHeader'
import '../whoWeAre/whoweare.css'

import part from '../../assets/partner.jpg'
import micro from '../../assets/micro.jpg'
import expert from '../../assets/expert.jpg'
import cloudwho from '../../assets/cloudwho.jpg'
import cloudexprt from '../../assets/exprtwho.jpg'
import consult from '../../assets/consult.jpg'
import stream from '../../assets/stream.jpg'
import cate from '../../assets/cate.jpg'
import CardWrapper from '../../components/card/CardWrapper'
const WhoWeAre = () => {
  const appData = [
    {
      appHead:"Pioneering Partnership:",
      appPara:"Leaders from across the Cloud industry have converged under the CloudStok banner, united by a common mission—to unravel the complexities that have posed challenges in the market. This collaboration represents a turning point for businesses aspiring to thrive in the digital realm.",
      appImg:part
    },
    {
      appHead:"Microservice Mastery:",
      appPara:"At CloudStok, our core competency lies in crafting microservice architecture that revolutionizes software development. We meticulously design each operation as a distinct unit, working in harmony to construct a unified software system. This approach not only enhances efficiency but also empowers businesses to adapt swiftly to changing requirements.",
      appImg:micro
    },
    {
      appHead:"Efficiency in Action:",
      appPara:"Our commitment doesn't end at crafting microservice architecture; it extends to nurturing these components for optimal performance. Each microservice is carefully migrated, deployed, and managed, forming a seamless IT infrastructure that operates with precision and reliability.",
      appImg:expert
    },
    {
      appHead:"Cloud Challenges Unveiled:",
      appPara:"CloudStok's inception was inspired by the recognition of challenges within the Cloud Computing landscape. Drawing on the expertise of industry veterans, CloudStok was conceived as a solution-driven entity, poised to tackle the very obstacles that had hindered progress.",
      appImg:cloudwho
    },
    {
      appHead:"Proven Expertise:",
      appPara:"Our team boasts extensive experience and mastery across major technologies, underpinning CloudStok's position as a guide for businesses seeking a transformative transition to digital infrastructure. Our proficiency ensures that you're not navigating this journey alone; CloudStok's expertise is your reliable companion.",
      appImg:cloudexprt
    },
    {
      appHead:"Tailored Consulting:",
      appPara:"Transitioning to a digital infrastructure involves strategic decision-making. Our consulting services act as a compass, guiding your organization through the intricate landscape. Our insights are finely tuned to your unique needs, making your transition a well-informed and effective endeavor.",
      appImg:consult
    },
    {
      appHead:"Streamlined Transition:",
      appPara:"Transitioning to a digital ecosystem can be overwhelming, but with CloudStok by your side, the journey becomes streamlined and manageable. Our consulting is designed to simplify complexities and ensure a smooth transition, so you can focus on reaping the benefits of digitalization.",
      appImg:stream
    },
    {
      appHead:"Innovation Catalyst:",
      appPara:"Beyond merely facilitating a transition, CloudStok is a catalyst for innovation. Our approach doesn't stop at transitioning technologies; it extends to igniting an innovative spirit within your organization. With CloudStok, digital transformation becomes a dynamic process that fuels growth and sparks creative evolution.",
      appImg:cate
    },
  ]
  return (
   <Layout>
   <div className="who-we-main-container">
   <Hero img={heroImg}/>
    <PageHeader head="ABOUT"
     heropara="Accelerate Your Digital Transformation Journey with CloudStok"
    //  herosubpara="Unlock the potential of your business with Cloudstok's comprehensive app development and modernization solutions. In a rapidly evolving digital landscape, staying competitive demands more than just an application—it requires a strategic masterpiece. At Cloudstok, we are your dedicated partners, working tirelessly to bring your vision to life."
     />
    <section >
      <CardWrapper appData={appData}/>
      <div className="future-container">
        <h1>Embrace the Future with CloudStok:</h1>
        <p>
        In the dynamic realm of Cloud Computing, CloudStok emerges as a sanctuary of progress. Weaving together the expertise of industry leaders, we shape solutions that empower businesses to embrace digital transformation confidently. Microservice architecture, our hallmark, epitomizes meticulous engineering, where individual operations synergize into a cohesive masterpiece. Our devotion extends to the complete lifecycle, ensuring each microservice thrives within your IT infrastructure. With an origin in addressing challenges, CloudStok is built on a foundation of foresight and innovation. Our proficiency in diverse technologies becomes the bedrock of your journey, ensuring you're not just transitioning systems but ushering in an era of enhanced efficiency and boundless innovation. As you navigate the transformation landscape, let CloudStok be your trusted partner, guiding you toward a digital horizon defined by success and continuous advancement.
        </p>
      </div>
    </section>
   </div>
   </Layout>
  )
}

export default WhoWeAre
